//登陆模块
const state = {
    teaPlanTerm: "登录"
};
const actions = {

};
const mutations = {
    teaPlanTerm(state, res) {
        state.teaPlanTerm = res;
    }
};

export default {
    state,
    mutations,
    actions
};