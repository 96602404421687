import {
      get,
      put,
      patch,
      post,
      fetch
} from "../ApiDecorator"; // 倒入 api
const Myactivity = {
      
      getActivityList(data) {
            return get("/learn/activity/pc/list", data);
      },
      getActivityrank(data) {
            return get("/learn/activity/rank", data);
      },
      getClassUpWorks(data) {
            return get("/learn/activity/queryClassUpWorks", data);
      },
      getqueryClassUsers(data) {
            return get("/learn/activity/queryClassUsers", data);
      },
      getselectByClass(data) {
            return fetch("/learn/activity/selectByClass/", data);
      },
      // 查询个人排行
      getstudentRank(data) {
            return get("/learn/activity/studentRank/", data);
      },
      // 查询班级排行
      getclassRank(data) {
            return get("/learn/activity/classRank/", data);
      },
      getqueryTeacherClass(data) {
            return get("/learn/activity/queryTeacherClass", data);
      },
      changeLike(data) {
            return fetch("/learn/activity/work/changeLike/", data);
      },
}
export default Myactivity;