import Home from "./Home";
import Login from "./login";
import Myclass from "./Myclass";
import Myactivity from "./myactivity";
import Example from './classExample'
import Live from './live'

const Api = {
    Home,
    Login,
    Myclass,
    Myactivity,
    Example,
    Live
};

// 导出
export default Api;