//主页模块
const state = {
    attend_id: {
        unitId: '',
        textId: '',
        course_name: '',
    },
    hint: '',
    message: false,



};
const mutations = {
    attend_data(state, res) {
        state.attend_id.unitId = res.unitId;
        state.attend_id.textId = res.textId;
        state.attend_id.course_name = res.course_name;
        sessionStorage.setItem('attend_id', JSON.stringify(state.attend_id))
    },
    hint_data(state, res) {
        state.hint = res;
    },
    message_data(state, res) {
        state.message = res;
    },


};
export default {
    state,
    mutations
};