import {
      get,
      put,
      patch,
      post,
      fetch
} from "../ApiDecorator"; // 倒入 api

const Example = {

      getExample(data) {
            return fetch("/teach/example/getExample/", data);
      },
}
export default Example;