// import Vue from 'vue'
// import VueRouter from 'vue-router'
// Vue.use(VueRouter)
// const Home = () => import("../views/Home.vue")
const details = () => import("../views/details/details.vue")
const logins = () => import("../views/login/logins.vue")
const independent = () => import("../views/login/loginsTwo.vue")
const independentReport = () => import("../views/login/loginsTree.vue")
const activity = () => import("../views/myactivity/activity.vue")
const attend = () => import("../views/attendclass/attend.vue")
const classroom = () => import("../views/attendclass/classroom.vue")
const pdfdemo = () => import("../views/attendclass/pdfdemo.vue")
const taskh = () => import("../views/task/taskh.vue")
const sampleReels = () => import("../views/myworks/sampleReels.vue")
const classExample = () => import("../views/classExample/classExample")
const Helpmanual = () => import("../views/Helpmanual/Helpmanual")
const assistant = () => import("../views/assistant/assistant")
const painter = () => import("../views/assistant/painter")
const Viewer = () => import("../views/attendclass/Viewer")
const showreel = () => import("../views/task/showreel.vue")
export default [
    {
        path: '/',
        redirect: '/logins'
    },
    {
        path: '/logins',
        name: 'logins',
        meta: {
            title: '登录',
            keepAlive: false
        },
        component: logins,
    },
    {
        path: '/independent',
        name: 'independent',
        meta: {
            title: '登录1',
            keepAlive: false
        },
        component: independent,
    },
    {
        path: '/independentReport',
        name: 'independentReport',
        meta: {
            title: '登录2',
            keepAlive: false
        },
        component: independentReport,
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            isBack: false,
            title: '首页',
            keepAlive: false
        },
        component: resolve => require(['@/views/Home'], resolve) // 路由懒加载,
    },
    {
        path: '/activity',
        name: 'activity',
        meta: {
            title: '备课',
            keepAlive: false
        },
        component: activity,
    }, {
        path: '/attend',
        name: 'attend',
        meta: {
            title: '上课',
            keepAlive: false
        },
        component: attend,
    },
    {
        path: '/classroom',
        name: 'classroom',
        meta: {
            title: '上课',
            keepAlive: false
        },
        component: classroom,
    },
    {
        path: '/pdfdemo',
        name: 'pdfdemo',
        meta: {
            title: 'pdf',
            keepAlive: false
        },
        component: pdfdemo,
    },
    {
        path: '/classExample',
        name: 'classExample',
        meta: {
            title: '入门课例',
            keepAlive: false
        },
        component: classExample,
    },
    {
        path: '/assistant',
        name: 'assistant',
        meta: {
            title: '工具',
            keepAlive: false
        },
        component: assistant,
    },

    {
        path: '/painter',
        name: 'painter',
        meta: {
            title: '画板',
            keepAlive: false
        },
        component: painter,
    },
    {
        path: '/Helpmanual',
        name: 'Helpmanual',
        meta: {
            title: '帮助手册',
            keepAlive: false
        },
        component: Helpmanual,
    },
    {
        path: '/taskh',
        name: 'taskh',
        meta: {
            title: '作业',
            keepAlive: false
        },
        component: taskh,
    }, {
        path: '/details',
        name: 'details',
        meta: {
            title: '个人',
            keepAlive: false
        },
        component: details,
    },
    {
        path: '/sampleReels',
        name: 'sampleReels',
        meta: {
            title: '作品集',
            keepAlive: false
        },
        component: sampleReels,
    },
    {
        path: '/Viewer',
        name: 'Viewer',
        meta: {
            title: 'Viewer',
            keepAlive: false
        },
        component: Viewer,
    },
    {
        path: '/showreel',
        name: 'showreel',
        meta: {
            title: 'showreel',
            keepAlive: true,
        },
        component: showreel,
    },
]
// const routes = [{
//     path: '/',
//     redirect: '/logins'
// },
// {
//     path: '/logins',
//     name: 'logins',
//     meta: {
//         title: '登录',
//         keepAlive: false
//     },
//     component: logins,
// },
// {
//     path: '/independent',
//     name: 'independent',
//     meta: {
//         title: '登录1',
//         keepAlive: false
//     },
//     component: independent,
// },
// {
//     path: '/independentReport',
//     name: 'independentReport',
//     meta: {
//         title: '登录2',
//         keepAlive: false
//     },
//     component: independentReport,
// },
// {
//     path: '/home',
//     name: 'Home',
//     meta: {
//         isBack: false,
//         title: '首页',
//         keepAlive: false
//     },
//     component: resolve => require(['@/views/Home'], resolve) // 路由懒加载,
// },
// {
//     path: '/activity',
//     name: 'activity',
//     meta: {
//         title: '备课',
//         keepAlive: false
//     },
//     component: activity,
// }, {
//     path: '/attend',
//     name: 'attend',
//     meta: {
//         title: '上课',
//         keepAlive: false
//     },
//     component: attend,
// },
// {
//     path: '/classroom',
//     name: 'classroom',
//     meta: {
//         title: '上课',
//         keepAlive: false
//     },
//     component: classroom,
// },
// {
//     path: '/pdfdemo',
//     name: 'pdfdemo',
//     meta: {
//         title: 'pdf',
//         keepAlive: false
//     },
//     component: pdfdemo,
// },
// {
//     path: '/classExample',
//     name: 'classExample',
//     meta: {
//         title: '入门课例',
//         keepAlive: false
//     },
//     component: classExample,
// },
// {
//     path: '/assistant',
//     name: 'assistant',
//     meta: {
//         title: '工具',
//         keepAlive: false
//     },
//     component: assistant,
// },

// {
//     path: '/painter',
//     name: 'painter',
//     meta: {
//         title: '画板',
//         keepAlive: false
//     },
//     component: painter,
// },
// {
//     path: '/Helpmanual',
//     name: 'Helpmanual',
//     meta: {
//         title: '帮助手册',
//         keepAlive: false
//     },
//     component: Helpmanual,
// },
// {
//     path: '/taskh',
//     name: 'taskh',
//     meta: {
//         title: '作业',
//         keepAlive: false
//     },
//     component: taskh,
// }, {
//     path: '/details',
//     name: 'details',
//     meta: {
//         title: '个人',
//         keepAlive: false
//     },
//     component: details,
// },
// {
//     path: '/sampleReels',
//     name: 'sampleReels',
//     meta: {
//         title: '作品集',
//         keepAlive: false
//     },
//     component: sampleReels,
// },
// {
//     path: '/Viewer',
//     name: 'Viewer',
//     meta: {
//         title: 'Viewer',
//         keepAlive: false
//     },
//     component: Viewer,
// },
// {
//     path: '/showreel',
//     name: 'showreel',
//     meta: {
//         title: 'showreel',
//         keepAlive: true,
//     },
//     component: showreel,
// },
// ]

// const router = new VueRouter({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes
// })

// export default router