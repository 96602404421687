import {
    get,
    put,
    del,
    patch,
    post,
    fetch,
    fetch_put
} from "../ApiDecorator"; // 倒入 api

const Live = {
    //获取课堂分页列表
    getRoomPage(data) {
        return get("/learn/tc/getRoomPage", data);
    },
    //查询课程状态
    dictionary(data) {
        return fetch("/learn/tc/dictionary/", data);
    },
    //根据班级码加入课堂
    joinClassByCode(data) {
        return fetch("/learn/tc/joinClassByCode/", data);
    },
    //根据班级ID加入课堂
    joinClass(data) {
        return fetch("/learn/tc/joinClass/", data);
    },
    //删除课程
    deleteClass(data) {
        return del("/learn/tc/delete/", data);
    },
    //查看RTC课程时回显
    rtcClass(data) {
        return fetch("/learn/tc/rtc/", data);
    },
    //查看LIVE课程时回显
    liveClass(data) {
        return fetch("/learn/tc/live/", data);
    },
    //用户创建RTC课程
    createClass(data) {
        return post("/learn/tc/rtc/create/", data);
    },
    //获取查看回放地址
    getPlaybackUrl(data) {
        return fetch("/learn/tc/getPlaybackUrl/", data);
    },
    //根据课堂ID得到详细数据
    getparticulars(data) {
        return fetch("/learn/tc/", data);
    },
    //查询本人指定创建课堂数量
    queryDailyCount(data) {
        return get("/learn/tc/queryDailyCount", data);
    },
    //用户创建RTC课程
    createliveClass(data) {
        return post("/learn/tc/live/create/", data);
    },
    //拉取上课信息
    getMpClassByCode(data) {
        return get("/learn/tc/getMpClassByCode/", data);
    },
    // 修改live课程信息
    liveUpdate(data1, data2) {
        return fetch_put("/learn/tc/live/update/", data1, data2);
    },
    // 修改rtc课程信息
    rtcUpdate(data1, data2) {
        return fetch_put("/learn/tc/rtc/update/", data1, data2);
    },

}
export default Live;