import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import App from './App.vue'
import routes from './router.js'
import axios from 'axios'
import Vuex from 'vuex'
Vue.use(Vuex);
Vue.prototype.$axios = axios;
const router = new VueRouter({
    mode: 'history',
    routes
})
import 'amfe-flexible/index'
import "element-ui/lib/theme-chalk/index.css";
import {
    message
} from './element/resetMessage.js';
Vue.prototype.$message = message;
// import './element/element-variables.scss'
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs; //全局使用dayjs
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)
import vueconciseslider from 'vue-concise-slider'
Vue.use(vueconciseslider)
import moment from "moment"
Vue.prototype.$moment = moment;
import './assets/icon/iconfont'
Vue.config.productionTip = false
import Api from "@/http/index";
Vue.prototype.$api = Api;
Vue.use(Api);
window.Api = Api;
import store from "./vuex/store";
//全局组件
import Subscribe from './Common_components/index'
Vue.use(Subscribe)
import VueStar from 'vue-star'
Vue.component('VueStar', VueStar);
//公共方法
import AccessPortrait from './Common_components/js/AccessPortrait.js'
Vue.prototype.$AccessPortrait = AccessPortrait;
//全局守卫
router.beforeEach((to, from, next) => {
    if (to.meta.title == '首页') {
        sessionStorage.iconIndex = 0;
    } else if (to.meta.title == '活动') {
        sessionStorage.iconIndex = 2;
    } else if (to.meta.title == '上课') {
        sessionStorage.iconIndex = 0;
    } else if (to.meta.title == '我的直播') {
        sessionStorage.iconIndex = 3;
    } else if (to.meta.title == 'showreel') {
        console.log('作品集')
        sessionStorage.iconIndex = 4;
    } else {
        sessionStorage.iconIndex = null;
    }
    next();
})
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')