import {
    get,
    put,
    patch,
    post
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const Login = {
    //判断身份是否注册
    regPhone(data) {
        return post("/user/regphone", data);
    },
    // 判断是否为教师
    isteacher(data) {
        return post("/user/isteacher", data);
    },
    //获取验证码
    getPhoneCode(data) {
        return post("/util/phonecode", data);
    },
    //获取验证码
    userslogincode(data) {
        return post("/auth/logincode", data);
    },
    //重置密码
    resetPwd(data) {
        return post("/user/resetpwd", data);
    },


















    // //用户登录
    // login(data) {
    //     return post("/learn/login", data);
    // },

    // //通过忘记密码 修改密码
    // getFix_pass(data) {
    //     return post("/learn/auth/user/phone/fix_pwd", data);
    // },
    // //修改手机号
    // //   getFix_phone(data) {
    // //     return put("/auth/user/fix_phone", data);
    // //   },

    // //发送手机验证码
    // getVerifyCode(data) {
    //     return post("/learn/auth/user/phone/send_code", data);
    // },
    // //验证手机验证码
    // getCheck_phone(data) {
    //     return post("/learn/auth/user/phone/check", data);
    // }
};

// 默认全部倒出
export default Login;