const CurriculumTime = () =>
    import ("../views/live/curriculumTime")
const LivePanel = () =>
    import ("../views/live/livePanel")
const LiveVideo = () =>
    import ("../views/live/liveVideo")
export default [{
        path: '/curriculumTime',
        name: 'curriculumTime',
        meta: {
            title: '课程时间',
            keepAlive: false
        },
        component: CurriculumTime,
    },
    {
        path: '/livePanel',
        name: 'livePanel',
        meta: {
            title: '我的直播',
            keepAlive: false
        },
        component: LivePanel,
    },
    {
        path: '/liveVideo',
        name: 'liveVideo',
        meta: {
            title: '我的回放',
            keepAlive: false
        },
        component: LiveVideo,
    },
]